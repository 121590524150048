<template>
  <el-main>
    <el-form class="el-form-search" label-width="120px">
      <el-form-item label="会员姓名：">
        <el-input v-model="keywords_type.member" size="small" placeholder="请输入会员姓名" @input="getKeywords($event, 1)"></el-input>
      </el-form-item>
      <el-form-item label="联系电话：">
        <el-input v-model="keywords_type.mobile" size="small" placeholder="请输入联系电话" @input="getKeywords($event, 2)"></el-input>
      </el-form-item>
      <el-form-item label="关联单号：">
        <el-input v-model="searchForm.order_sn" size="small" placeholder="请输入关联单号"></el-input>
      </el-form-item>
      <el-form-item label="收益/消费类型：">
        <el-select v-model="searchForm.service_type" size="small">
          <el-option label="全部" value=""></el-option>
          <el-option label="时价" :value="1"></el-option>
          <el-option label="合伙人入驻" :value="2"></el-option>
          <el-option label="项目合作" :value="3"></el-option>
          <el-option label="项目活动" :value="4"></el-option>
          <el-option label="应用数据" :value="5"></el-option>
          <el-option label="实物商品" :value="6"></el-option>
          <el-option label="广告" :value="7"></el-option>
          <el-option label="关键词" :value="8"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="佣金类目：">
        <el-select v-model="searchForm.identity_type" size="small">
          <el-option label="全部" value=""></el-option>
          <el-option label="经营分佣" :value="0"></el-option>
          <el-option label="城市合伙人分佣" :value="1"></el-option>
          <el-option label="区域合伙人分佣" :value="2"></el-option>
          <el-option label="市场合伙人分佣" :value="3"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="产生时间：">
        <el-date-picker
          v-model="searchForm.create_time"
          size="small"
          type="daterange"
          value-format="timestamp"
          range-separator="~"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label=" " label-width="35px">
        <el-button size="small" @click="search" type="primary">搜索</el-button>
        <el-button size="small" @click="searchClear" type="text">清除搜索条件</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="list" :header-cell-style="{ 'background-color': '#F8F9FA' }" row-key="id">
      <template slot="empty">
        <No />
      </template>
      <el-table-column prop="mechanic_info.name" label="会员姓名" width="210" align="center"></el-table-column>
      <el-table-column prop="mechanic_info.mobile" label="联系电话" width="210" align="center"></el-table-column>
      <el-table-column prop="service_type" label="收益/消费类型" width="180" align="center">
        <template v-slot="{ row }">
          {{ row.service_type | orderName }}
        </template>
      </el-table-column>
      <el-table-column prop="identity_type" label="佣金类目" width="180" align="center">
        <template v-slot="{ row }">
          {{ row.identity_type == 0 ? '经营分佣' : row.identity_type == 1 ? '城市合伙人分佣' : row.identity_type == 2 ? '区域合伙人分佣' : '市场合伙人分佣' }} （{{ row.percentage }}%）
        </template>
      </el-table-column>
      <el-table-column prop="money" label="收益金额" width="150" align="center">
        <template v-slot="{ row }">￥{{ row.money }}</template>
      </el-table-column>
      <el-table-column prop="type" label="收益状态" width="150" align="center">
        <template v-slot="{ row }">{{ row.type == 1 ? '待入账' : row.type == 2 ? '已入账' : row.type == 3 ? '已失效' : row.type == 4 ? '冻结中' : '提现中' }}</template>
      </el-table-column>
      <el-table-column prop="order_info.amount" label="订单金额" width="150" align="center">
        <template v-slot="{ row }">￥{{ row.order_info.amount }}</template>
      </el-table-column>
      <el-table-column prop="integral" label="支付积分" width="150" align="center"></el-table-column>
      <el-table-column prop="order_sn" label="关联单号" width="210" align="center"></el-table-column>
      <el-table-column prop="create_time" label="产生时间" width="200" align="center">
        <template v-slot="{ row }">{{ row.create_time | getDateformat }}</template>
      </el-table-column>
    </el-table>
    <Paging :total="total_number" :page="searchForm.page" :pageNum="searchForm.rows" @updatePageNum="updateData"></Paging>
  </el-main>
</template>

<script>
import { getDateformat } from '@/util/getDate';
import Paging from '@/components/paging';
import _ from 'lodash';
export default {
  components: {
    Paging
  },
  data() {
    return {
      keywords_type: {
        member: '',
        mobile: '',
      },
      searchForm: {
        page: 1,
        rows: 10,
        keyworks_type: '',
        member: '',
        mobile: '',
        order_sn: '',
        keyworks: '',
        service_type: '',
        identity_type: '',
        member_id: '',
        create_time: [],
      },
      list: [],
      total_number: 0,
    };
  },
  filters: {
    getDateformat: getDateformat,
    orderName(type) {
      let name = '';
      switch (type) {
        case 1:
          name = '时价';
          break;
        case 2:
          name = '合伙人入驻';
          break;
        case 3:
          name = '项目合作';
          break;
        case 4:
          name = '项目活动';
          break;
        case 5:
          name = '应用数据';
          break;
        case 6:
          name = '实物商品';
          break;
        case 7:
          name = '广告';
          break;
        case 8:
          name = '关键词';
          break;
      }
      return name;
    },
  },
  created() {
    this.searchForm.member_id = this.$route.query.member_id || '';
    this.searchForm.service_type = this.$route.query.service_type || '';
    this.getList();
  },
  methods: {
    search() {
      this.searchForm.page = 1;
      this.getList();
    },
    searchClear() {
      this.searchForm = {
        page: 1,
        rows: 10,
        keyworks_type: '',
        member: '',
        mobile: '',
        order_sn: '',
        keyworks: '',
        service_type: '',
        identity_type: '',
        member_id: '',
        create_time: [],
      };
      this.getList();
    },
    updateData(val, status) {
      if (status == 0) {
        this.searchForm.rows = val;
      } else {
        this.searchForm.page = val;
      }
      this.getList();
    },
    resetKeywordType(key) {
      for (let i in this.keywords_type) {
        if (i != key) this.keywords_type[i] = '';
      }
    },
    getKeywords(val, type) {
      let searchForm = this.searchForm;
      switch (type) {
        case 1:
          this.resetKeywordType('member');
          searchForm.keyworks_type = 'member';
          break;
        case 2:
          this.resetKeywordType('mobile');
          searchForm.keyworks_type = 'mobile';
          break;
      }
      searchForm.keyworks = val;
    },
    getList() {
      let searchForm = _.cloneDeep(this.searchForm);
      let data = {
        member_id:searchForm.member_id,
        page: searchForm.page,
        rows: searchForm.rows,
        order_sn: searchForm.order_sn,
      };
      if (searchForm.keyworks) {
        data.keyworks = searchForm.keyworks;
        data.keyworks_type = searchForm.keyworks_type;
      }
      if (searchForm.service_type) {
        data.service_type = searchForm.service_type;
      }
      if (searchForm.identity_type) {
        data.identity_type = searchForm.identity_type;
      }
      if (searchForm.create_time) {
        for (let i in searchForm.create_time) {
          searchForm.create_time[i] = searchForm.create_time[i] / 1000;
        }
        data.create_time = searchForm.create_time;
      }
      this.$axios.post(this.$api.gateway.member.cashLogList, data).then(res => {
        if (res.code == 0) {
          this.list = res.result.list;
          this.total_number = res.result.total;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background: #fff;
}
</style>